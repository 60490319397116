.tl-document-file {
  width: 100%;

  tbody {
    border-top: none;

    tr:first-child {
      td {
        border-top: 1px solid #ccc;

        &:first-child {
          border-top: none;
        }
      }
    }
  }

  tr {
    border: none;
    background-color: $light-gray;
  }

  th {
    border-bottom: none;
    padding: .5rem 0;
  }

  td {
    padding: 1rem 0;

    &:first-child {
      width: 5%;
      min-width: 27px;
      text-align: center;

      img {
        padding-right: .4rem;
      }
    }

    &:nth-child(2),
    &:nth-child(3) {
      width: 40%;
      border-bottom: 1px solid #ccc;
    }

    &:nth-child(2) {
      width: 45%;
    }

    &:last-child {
      width: 10%;
      border-bottom: 1px solid #ccc;
    }
  }
}

.view-documents {
  .views-row {
    background-color: $light-gray;
    padding: 1.5rem 3rem 2.25rem;
    margin-top: 1rem;

    .views-field-title {
      margin-bottom: 1rem;

      @include breakpoint(medium) {
        font-size: 1.8rem;
      }
    }

    .tl-document-file {
      a {
        color: $black;
        font-weight: bold;
      }
    }
  }

  &.view-display-id-pane_related {
    .views-row {
      padding: 0;
      margin: 0;
      clear: both;
      padding-top: 1rem;
    }

    .file {
      float: left;

      a {
        display: none;
      }
    }

    .views-field-title {
      font-size: 1rem;
      overflow: hidden;
      margin-bottom: 0;
      margin-top: -.3rem;

      p {
        margin-bottom: 0;
      }

      a {
        color: $body-font-color;
      }
    }
  }
}

.view-documents-index {
  .file-document a {
    display: none;
  }
}
