table {
  background-color: $light-gray;
  width: 100%;
  thead {
    th {
      border-bottom: 1px solid #ccc;
    }
  }
  th, td {
    padding: 1rem 0.5rem;
  }
  tbody {
    border: 0;
    tr, td {
        background-color: $light-gray;
        border-bottom: 1px solid #ccc;
    }
  }
}

.view-documents-index table {
  td:first-child {
    padding-left: 0;
    width: 36px;
  }
  td:last-child {
    padding-right: 0;
    white-space: nowrap;
  }
  td:nth-of-type(3) {
    word-break: break-all;
    word-wrap: break-word;
    font-size: 0.8rem;
  }

  tr {
    td {
      &.preview-link {
        a {
          display: none;
        }
      }
    }

    &.application-pdf {
      td {
        &.preview-link {
          a {
            display: inline;
          }
        }
      }
    }
  }

}

.table-wrapper {
  background: $transparent-gray;
  padding: 1rem;
}