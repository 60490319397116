.tl-multiple-drag-placeholder {
  display: none;
}

.tl-header-table td,
.tl-row-table td {
  font-size: 1rem;
  border: none;

  &.remove input {
    margin-bottom: 1rem;
  }
}

.tl-row-table tr {
  border: none;
}

.tl-widget-wrapper {
  border-bottom: 1px solid #ccc;
}