.winwheel-container {
  position: relative;
  display: inline-block;

  .pointer {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 11%;
    left: 50%;
    background: url(../img/wheel-pointer.svg) no-repeat center;
    height: 55px;
    width: 55px;
    background-size: 45px;

    @include breakpoint(medium) {
      height: 95px;
      width: 95px;
      background-size: 70px;
    }
  }

  .start {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    background: url(../img/logos/logo.svg) no-repeat center;
    height: 95px;
    width: 95px;
    border-radius: 50%;
    cursor: pointer;
    padding: 1rem;
    background-size: 50px;
    border: 6px solid $white;
    background-color: $yellow;

    @include breakpoint(medium) {
      height: 128px;
      width: 128px;
      background-size: 85px;
      border-width: 8px;
    }

    &.disabled {
      cursor: default;
      filter: grayscale(100%);
    }
  }
}

.winwheel-canvas {
  background: url(../img/wheel-legs.svg) no-repeat center;
  background-size: contain;
  width: 100%;
}

.ui-dialog {
  &.win-modal {
    .ui-dialog-titlebar {
      display: none;
    }

    .ui-dialog-content {
      text-align: center;
      font-size: 1.5rem;

      a {
        outline: none;
      }

      img {
        max-width: 150px;
      }
    }

    .ui-dialog-buttonpane {
      .ui-dialog-buttonset {
        display: flex;
        .ui-button {
          background: $green;
          border: none;
          outline: none;
          color: $black;

          &:first-of-type {
            display: none;
            background: $primary;
            color: $white;
          }

          .ui-button-text {
            font-size: .9rem;
          }
        }
      }
    }

    &.contains-link {
      .ui-dialog-buttonpane {
        .ui-dialog-buttonset {
          .ui-button {
            &:first-of-type {
              display: block;
            }
          }
        }
      }
    }
  }
}

.node-type-wheel {
  .breadcrumbs-wrapper {
    display: block;
  }

  .onecol-layout {
    background: url(../img/wheel_bg.svg) no-repeat center;
    background-size: cover;
    margin: 2rem 0;
    padding-bottom: 2rem;

    .pane-node-title {
      color: $white;
      text-align: center;
      margin-top: 2rem;
    }
  }

  .pane-node-field-wheel-segments {
    text-align: center;
  }
}

.page-dashboard-wheels {
  .onecol-layout {
    .top {
      background: url(../img/wheel_bg.svg) no-repeat center;
      background-size: cover;
      margin: 2rem 0;
      max-width: 100%;
      padding-bottom: 2rem;
    }
  }
}

.node-wheel-form {
  .field-name-field-wheel-segments {
    .tabledrag-toggle-weight-wrapper {
      display: none;
    }

    table {
      margin-bottom: 1rem;

      th {
        padding-left: 1.5rem;
        padding-right: 1.5rem;

        label {
          font-weight: bold;
        }
      }

      tbody {
        label {
          display: none;
        }

        [type="text"] {
          margin-bottom: 0;

          @include breakpoint(large) {
            width: 60%;
          }
        }

        .field-multiple-drag {
          .tabledrag-handle {
            box-sizing: content-box;
            margin-left: 0;
            padding-right: 0;
          }

          .tabledrag-changed {
            width: 100%;
            display: inline-block;
            text-align: center;
          }
        }
      }
    }

    input[type="submit"] {
      background-color: $secondary;
      color: $black;

      &:hover {
        background-color: scale-color($secondary, $lightness: -15%);
      }
    }
  }

  fieldset {
    border: 1px solid $medium-gray;
    padding: 1rem;
  }
}

.view-wheels {
  margin-bottom: 2rem;

  &.promoted {
    text-align: center;
    margin: 2rem auto;

    h3 {
      color: $white;
      font-size: 1.4rem;

      @include breakpoint(medium) {
        font-size: 2.2rem;
      }
    }
  }
}