.entity-culture-order {
  h2 {
    margin-top: 1em;
    margin-bottom: 1em;
  }
}

#eck-entity-form-edit-culture-order-culture-order {
  .field-name-field-pupils {
    .form-wrapper {
      float: left;
      width: 25%;
      padding:0 1rem;
    }
    input[type="submit"] {
      margin-top: 20px;
    }
  }
}