.backdrop-tl-webform {
  position: fixed !important;
}

.modal-tl-webform {
  top: 0 !important;

  div.modal-forms-modal-content {
    .popups-container {
      overflow: visible;
      height: auto;
      background: transparent;
      padding: 1rem;

      @include breakpoint (large) {
        padding: 2rem;
      }

      .modal-header {
        padding: 0;

        @include breakpoint (large) {
          text-align: center;
        }

        #modal-title {
          display: block;
          font-family: $body-font-family;
          font-size: 1.2rem;
          font-weight: 700;
          margin-bottom: 1rem;
          margin-left: 1rem;
        }

        .popups-close {
          height: auto;
          width: auto;
          display: block;
          background-image: url(../img/icons/close.svg);
          background-position: center top;
          background-size: 20px;
          background-repeat: no-repeat;
          position: absolute;
          right: 2rem;
          top: 2rem;
          padding-top: 2rem;
          text-indent: initial;
          z-index: 1;
          color: $black;
          font-size: 1rem;
          font-weight: 700;
        }
      }

      #modal-content {
        overflow: visible;
        height: auto !important;
        width: 100% !important;
        max-width: $grid-row-width;
        margin: 0 auto;
        padding: 0 1rem;

        .webform-client-form {
          input[type='text'],
          input[type='email'],
          select {
            border: none;
            border-bottom: 3px solid $dark-gray;
            box-shadow: none;
            &:focus {
              border-bottom-color: $green;
            }
          }
        }
      }
    }
  }
}

.webform-client-form {
  &.webform-client-form-8 {
    .webform-steps-buttons {
      margin-top: 1rem;
      margin-bottom: 2rem;

      @include breakpoint (large) {
        text-align: center;
        margin: 1rem 0;
      }

      .step-button {
        width: 15px;
        height: 15px;
        background-color: $medium-gray;
        margin: 0 1.2rem 0 0;
        border-radius: 50%;
        background-size: 0;
        text-indent: -9999px;
        padding: 0;
        opacity: 1;
        outline: none;

        @include breakpoint (large) {
          margin: 0 .6rem;
        }

        &.active {
          width: 30px;
          height: 30px;
          background-size: 60%;
          background-color: $green;
          background-repeat: no-repeat;
          background-position: center;
        }
      }

      .ajax-progress-throbber {
        display: none;
      }
    }

    .webform-component--delivery-info {
      legend {
        display: none;
      }
    }

    @include breakpoint (large) {
      .webform-component--school-info,
      .webform-component--delivery-info {
        float: left;
        width: 50%;
        clear: none;
      }

      .webform-component--school-info {
        padding-right: 1rem;
      }

      .webform-component--delivery-info {
        padding-left: 1rem;

        legend {
          display: block;
          visibility: hidden;
        }
      }
      .webform-component--billing-info .fieldset-wrapper{
        display: flex;
        flex-flow: row wrap;
        .form-item {
          flex: 1 50%;
          padding-right: 1rem;
          padding-left: 1rem;
        }
        .webform-component--billing-info--billing-street-address {
          order: 1;
        }
        .webform-component--billing-info--billing-postal-code {
          order: 3;
          flex: 1 15%;
        }
        .webform-component--billing-info--billing-postal-place {
          order: 4;
          flex: 1 35%;
        }
        .webform-component--billing-info--resource-number {
          order: 5;
        }
        .webform-component--billing-info--kontortelefon {
          order: 6;
        }
        .webform-component--billing-info--org-number {
          order: 2;
        }
      }
      .webform-component--contact-person .fieldset-wrapper{
        display: flex;
        flex-flow: row wrap;
        .form-item {
          flex: 1 50%;
          padding-right: 1rem;
          padding-left: 1rem;
        }
        .webform-component-markup {
          flex: 1 100%;
        }
      }
    }

    .form-actions {
      clear: both;

      input {
        &.webform-previous {
          margin-top: 2rem;
        }

        &.webform-next,
        &.webform-submit {
          float: right;
          margin-top: 1rem;

          @include breakpoint(large) {
            margin-top: 2rem;
          }
        }
      }

      .ajax-progress-throbber {
        display: none;
      }
    }
  }
}

#tl-culture-order-form {
  .step-buttons {
    ul {
      text-align: center;
      li {
        width: 15px;
        height: 15px;
        background-color: $medium-gray;
        margin: 0 1.2rem 0 0;
        border-radius: 50%;
        background-size: 0;
        text-indent: -9999px;
        padding: 0;
        opacity: 1;
        outline: none;
        display: inline-block;
        vertical-align: middle;
        &.active {
          width: 30px;
          height: 30px;
          background-size: 60%;
          background-color: $green;
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    }
  }
  label {
    font-weight: 700;
    font-size: 1rem;
  }
  input[type='text'],
  input[type='email'],
  select {
    border: none;
    border-bottom: 3px solid $dark-gray;
    box-shadow: none;
    background: $transparent-gray;
    &:focus {
      border-bottom-color: $green;
    }
  }
  .form-item, h2, p, .form-actions, .form-table-wrapper, .item-list, #tl_culture_order_tabledrag {
    clear: both;
    padding-right: 2%;
    padding-left: 2%;
    margin-top: 1em;
    margin-bottom: 1em;
  }
  .slide-cross, .tabledrag-toggle-weight-wrapper {
    display: none;
  }
  #tl_culture_order_tabledrag {
    thead tr th:first-child {
      display: none;
    }
  }
  .form-item-postal-nr {
    float: left;
    width: 30%;
    clear: none;
  }
  .form-item-postal-place {
    float: left;
    width: 70%;
    clear: none;
  }
  .form-actions {
    input {
      margin-top: 2rem;
      width: 10rem;
    }
    #edit-next {
      background-color: $green;
      float: right;
    }
  }
  input[type=submit].green {
    background-color: $green;
  }
  .form-managed-file {
    input {
      margin: 1rem 0;
    }
  }
  .form-table-wrapper, #tl_culture_order_tabledrag {
    .fieldset-wrapper, #tl_culture_order_table-fieldset-wrapper {
      background: $transparent-gray;
      padding: 1rem;
    }
  }
  .form_info_box {
    background: rgba(247,247,247,0.95);
    margin-right: 2%;
    margin-left: 2%;
    padding-top: 1rem;
    p {
      margin-top: 0.2rem;
      margin-bottom: 0.2rem;
    }
    input[type=submit] {
      margin-top: 1rem;
      margin-left: 2%;
    }
  }
}
