.page-dashboard {
  #main-content {
    h1#page-title {
      display: none;
    }

    h2.school-name {
      margin-bottom: 1.5rem;

      a {
        font-size: 1rem;
        vertical-align: middle;
        margin-left: .5rem;
        font-family: $body-font-family;
        font-weight: 600;
      }
    }

    #tabs {
      position: relative;

      &:after, &:before {
        position: absolute;
        content:'';
        height: 100%;
        width: 30px;
        top: 0;

        @include breakpoint(medium) {
          display: none;
        }
      }

      &:after {
        right: 0;
        background: -webkit-linear-gradient(to right, rgba(255, 255, 255, 0) , #fff);
        background: linear-gradient(to right, rgba(255, 255, 255, 0) , #fff);
      }

      &:before {
        left: 0;
        background: -webkit-linear-gradient(to left, rgba(255, 255, 255, 0) , #fff);
        background: linear-gradient(to left, rgba(255, 255, 255, 0) , #fff);
      }
    }

    .tabs {
      &.columns {
        display: none;
      }

      &.primary {
        border-bottom: 0;
        padding: 0;
        margin: 0;
        overflow: auto;
        padding-left: 10px;

        li {
          @include breakpoint(medium) {
            display: inline-block;
            float: left;
          }

          a {
            border: none;
            border-bottom: 5px solid $dark-gray;
            background-color: transparent;
            padding: 0;
            color: $body-font-color;
            display: inline-block;
            margin-right: 1.3rem;
            margin-bottom: 1rem;
            font-size: 1.1rem;
            padding-bottom: .5rem;
            font-weight: bold;
            @include breakpoint(medium) {
              min-width: 145px;
            }

            &.active {
              border-bottom-color: $green;
            }
          }
        }
      }
    }

    #user-profile-form {
      background-color: transparent;
      margin-bottom: 0;
      padding: 0;

      .pane-form {
        background-color: $light-gray;
        margin-bottom: 2rem;
        margin-top: 1rem;
        padding: .5rem 2rem 0;
      }
    }

    input[type="submit"] {
      font-size: 1rem;
      //color: $black;
    }
  }
}

.page-dashboard,
.page-user,
.page-admin-structure-entity-type-school-school-edit {
  #main-content {
    label {
      font-weight: 600;
    }

    input[type="text"],
    input[type="password"],
    input[type='email'],
    select {
      border: none;
      border-bottom: 3px solid $dark-gray;
      box-shadow: none;
      &:focus {
        border-bottom-color: $green;
      }
    }

    .locality-block {
      & > div[class$="-und-0-postal-code"] {
        margin-right: 0;
        padding-right: 1rem;
        width: 40%;
      }

      & > div[class$="-und-0-locality"] {
        margin-right: 0;
        width: 60%;
      }
    }
  }

  .view-header > a {
    display: block;
    padding: 1rem 0 1rem;
    font-size: 1.2rem;
  }
}

.field-name-field-user-address {
  legend {
    display: none;
  }
}

#user-profile-form, #tl-entityreference-widget-simpleedit-form {
  background-color: $light-gray;
  margin-bottom: 2rem;
  padding: .5rem 2rem 0;

  .form-type-textfield,
  .form-type-password {
    margin: 0;
    padding: .25rem 0;
  }

  @include breakpoint(medium) {
    .group-left,
    .group-right {
      padding: 1rem 1rem 1.5rem;
      float: left;
      width: 50%;
    }

    .group-right {
      float: right;
    }
  }

  .form-actions {
    clear: both;
    background-color: $white;
    margin-left: -2rem;
    margin-right: -2rem;
    padding: 2rem 0 1rem 0;

    .form-submit {
      width: 100%;
      color: $black;
      font-size: 1rem;
      margin-left: 0;
      margin-right: 0;

      &#edit-submit {
        background-color: $green;
      }

      &#edit-cancel {
        color: $white;
      }

      @include breakpoint(medium) {
        float: left;
        width: 49%;

        &#edit-submit {
          margin-right: 1%;
        }

        &#edit-cancel {
          margin-left: 1%;
        }
      }
    }
  }

  .change-password-link {
    margin: 1rem 0;

    a {
      font-weight: bold;
    }
  }
}

.login-layout {
  background-color: $light-gray;

  h1#page-title,
  .tabs {
    display: none;
  }

  h1.title {
    font-size: 2.5rem;
  }
}

#change-pwd-page-form {
  background-color: $light-gray;
  margin-bottom: 2rem;
  padding: .5rem 2rem 1rem;

  .form-submit {
    background-color: $green;
    color: $black;
    font-size: 1rem;
  }
}

#user-login-form,
#user-pass,
#openid-connect-login-form {
  label {
    span.form-required {
      display: none;
    }
  }

  .item-list {
    ul {
      list-style: none;
      margin: 0;
      padding: .75rem 0;

      li {
        margin: 0;

        a {
          color: $body-font-color;
        }
      }
    }
  }

  .form-submit {
    width: 100%;
    background-color: $green;
    color: $black;
    font-size: 1rem;
  }
}

.field-name-field-school-admin-reference,
.field-name-field-school-staff-reference {
  .field-add-more-submit {
    padding: .75rem;
    background-color: $light-gray;
    margin-top: 1.5rem;
    color: $black;

    &:hover {
      color: $black;
      background-color: $medium-gray;
    }
  }

  .tabledrag-toggle-weight-wrapper,
  table.sticky-header {
    display: none;
  }

  .field-multiple-table {
    width: 100%;

    thead, tbody {
      td, th {
        padding: 0 1.25rem;
      }
      th {
        padding-top: 1rem;
        padding-bottom: 1rem;
      }
    }

    tbody {
      border: none;

      tr {
        border-bottom: none;
        background-color: $light-gray;

        td {


          &.field-multiple-drag {
            display: none;
          }

          .tl-widget {
            width: 100%;

            td {
              &.remove {
                position: relative;
                background-color: transparent;

                &:before {
                  position: absolute;
                  content: "\00d7";
                  font-size: 3rem;
                  line-height: 1.6rem;
                  margin-top: .5rem;
                }

                input {
                  padding-left: 1.6rem;
                  position: relative;

                  &:hover {
                    color: $black;
                  }
                }
              }
            }

            thead {
              display: table-header-group;

              th {
                border-bottom-width: 1px;
              }
            }
          }
        }
      }
    }

    input[type="submit"] {
      margin-top: 1rem;
      padding: 0;
      background-color: transparent;
      color: $black;

      &.add-user {
        margin-top: 2.5rem;
        padding: .9rem;
        background-color: $medium-gray;
      }
    }

    .form-type-textfield {
      margin-top: 1rem;
      padding-right: 1rem;
      width: 50%;
      float: left;
    }

    .register-form-fieldset {
      border: 1px solid #ccc;
      margin: 1.125rem 0;
      padding: 1.25rem;

      .form-type-textfield[class*="register-form-email"] {
        margin-top: 0;
        width: 100%;
      }

      .form-type-select[class*="register-form-field-role"] {
        clear: both;
        margin-top: 1rem;
        padding-right: 1rem;
      }

      input[id*="form-actions-create"] {
        background-color: $medium-gray;
        padding: .5rem 1rem;
        margin-right: 1rem;
      }
    }

    select[name*="_weight"] {
      display: none;
    }
  }

  .user-import {
    margin-left: 1rem;
    position: relative;
    top: 5px;
  }
}

.page-dashboard-school-info {
  .pane-form {
    background-color: $light-gray;
    margin-bottom: 2rem;
    margin-top: 1rem;
    padding: .5rem 2rem 0;

    .pane-title {
      margin-bottom: 0;
      margin-top: 1rem;
    }

    input {
      margin-bottom: 0;
    }

    .form-type-textfield,
    .form-type-select {
      margin: 0;
      padding: .75rem 0;
    }

    .field-name-field-school-address,
    .field-name-field-billing-address {
      legend {
        display: none;
      }
    }

    .field-name-field-school-website {
      .link-field-url {
        .form-item {
          margin: 0;
          padding: 0;
        }
      }
    }

    .field-name-field-school-type {
      input.form-radio,
      [type="radio"] + label {
        vertical-align: middle;
      }
    }

    .billing-address-label {
      padding-top: 2rem;
    }

    .field-name-field-contract {
      input[name$="_remove_button"].form-button-disabled {
        display: none;
      }
    }

    .form-actions {
      clear: both;
      background-color: $white;
      margin-top: 2em;
      margin-left: -2rem;
      margin-right: -2rem;
      padding: 2rem 0 1rem 0;
    }

    @include breakpoint(medium) {
      .pane-title {
        padding: 0 1rem;
      }

      .group-left,
      .group-right {
        padding: 0 1rem;
        float: left;
        width: 50%;
      }

      .group-right {
        float: right;
      }

      .billing-address-label,
      .field-name-field-org-number,
      .field-name-field-crm-comment {
        clear: both;
        padding-left: 1rem;
        padding-right: 1rem;
      }

      .field-name-field-billing-address {
        float: left;
        width: 50%;
        padding: 0 1rem;
      }

      .field-name-field-resource-number,
      .field-name-field-kontortelefon {
        float: right;
        width: 50%;
        padding: 0 1rem;
      }
    }
  }

  &.view-mode {
    .pane-entity-field {
      margin: .5rem 0;
    }
  }
}
