.panel-display {
  &.onecol-full-width-layout {
    .top, .middle, .bottom {
      padding: 2rem 2.5rem 2rem 2rem;
      margin-bottom: 2rem;
    }
  }
}

.page-kulturkortet-fordeler, .page-medlemsskoler-skoler {
  .main_top {
    background-color: $light-gray;
    padding: 2rem 1rem;
    margin-bottom: 2rem;
    @include breakpoint(large) {
      display: flex;
      flex-flow: row wrap;
    }
    .pane-title {
      font-size: 0.9rem;
      font-weight: 700;
    }
    .panel-pane {
      flex-grow: 1;
      flex-basis: 0;
      padding: 0 1rem;
      .form-item {
        margin-top: .5rem;
      }
      select, input {
        margin-bottom: 0;
        border: 0;
        border-bottom: 2px solid $medium-gray;
        box-shadow: none;
        &:focus {
          border-color: $green;
        }
      }
      input[type="submit"] {
        border: 0;
        margin-bottom: 2px;
      }
      .form-submit {

      }
    }
  }
  .schools-table-exposed {
    background-color: $light-gray;
    padding: 1rem 2rem;
    td {
      text-transform: lowercase;
      &::first-letter {
        text-transform: uppercase;
      }
    }
  }
}

.page-kulturkortet-fordeler {
  .form-submit {
    display: none;
  }
}

.view-advantages {

  .views-field-title {
    margin-bottom: 1rem;
    font-weight: 700;
    font-size: 1.57rem;
  }
  .views-field-body {
    font-size: 1.4rem;
  }
  .views-field-field-nettside {
    margin-top: 1rem;
    font-weight: 700;
    a {
      color: $black;
    }
  }

  p {
    margin-bottom: 0;
  }
  .views-row {
    $row_margin: 1.8%;
    background-color: $light-gray;
    padding: 2rem 2rem;
    margin-bottom: 2rem;
  }
}

.culture-card-cards {
  img {
    padding: 1rem;
    @include breakpoint(medium) {
      max-width: 50%;
    }
  }
}

.page-dashboard-documents, .page-dashboard-search {
  .main-column .pane-content {
    background-color: $light-gray;
    padding: 2rem;
  }
  .top {
    margin-bottom: 2rem;
  }
  .side-column {
    .side-column__inner {
      background-color: $light-gray;
      padding: 2rem;
    }
    .pane-title {
      font-size: 1.4rem;
    }
    .panel-pane {
      margin-bottom: 2rem;
    }
  }
}
