header {
  position: relative;
  z-index: 499;
}

nav.main-menu {
  position: absolute;
  background-color: $transparent-gray;
  z-index: 100;
  width: 100%;
  padding: 1.2rem 2rem 2rem 1rem;
  height: 100vh;
  left: -100%;
  transition: 300ms ease-in-out;
  overflow: scroll;

  @include breakpoint(large) {
    width: 35rem;
    left: -35rem;
    overflow: hidden;
  }

  &.visible {
    left: 0;

    .menu-title {
      opacity: 0;
    }
  }

  .menu-title {
    width: 100%;
    text-align: center;
    font-family: $body-font-family;
    font-size: 1.2rem;
    font-weight: 700;
    margin-bottom: 2rem;
    transition: 700ms;
    transition-delay: 300ms;
    opacity: 0;
  }

  ul {
    list-style-type: none;
    margin-bottom: 3rem;
    margin-left: 0;

    li {
      list-style: none;
      margin: 0;
      padding: 0;

      a {
        font-size: 1.2rem;
        color: black;
      }
    }
  }

  // 1st level
  [class*="menu-block-"] {
    & > ul {
      margin-bottom: 0;

      & > li {
        display: block;
        padding-right: .9375rem;
        padding-left: .9375rem;

        @include breakpoint(medium) {
          float: left;
          width: 50%;
        }

        & > a {
          color: black;
          font-family: 'Eczar';
          font-size: 1.8rem;
        }
      }
    }
  }
  .feature-menu-mob {
    padding-right: .9375rem;
    padding-left: .9375rem;
    font-family: 'Eczar';
    a {
      font-size: 1.8rem;
    }
    ul {
      margin-bottom: 1.5rem;
      padding-bottom: .8rem;
      border-bottom: 1px solid #000;
    }
    @include breakpoint(large) {
      display: none;
    }
  }
}

.menu-trigger {
  color: $black;
  background-image: url(../img/icons/menu.svg);
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 1.2rem;
  width: 15rem;
  height: 3.8rem;
  display: block;
  padding: 1rem 1rem 1rem 2rem;
  font-size: 1.2rem;
  font-weight: 700;
  position: absolute;
  top: 0.1rem;
  left: 1rem;
  z-index: 101;
  transition: 300ms ease-in-out;
  &:hover {
    color: $black;
    text-decoration: underline;
  }

  @include breakpoint(large) {
      left: 2rem;
  }

  .open {
    display: none;
  }

  &.open {
      background-image: url(../img/icons/close.svg);
      .open {
        display: inline-block;
      }
      .closed {
        display: none;
      }
  }
}

.not-logged-in .hide-for-anon {
  display: none;
}

nav.feature-menu {
  position: absolute;
  right: 0;
  top: 0;
  display: none;
  z-index: 1;

  @include breakpoint(large) {
    right: 1.5rem;
    top: 1.5rem;
    display: block;
    margin-left: rem-calc(235);
  }

  .menu {
    margin: 0;

    li {
      list-style: none;
      margin: 0;
      padding: 0;

      @include breakpoint(large) {
        float: left;
      }

      a {
        background-color: $button-background;
        color: $button-color;
        width: 10rem;
        font-weight: 700;
        font-size: .9rem;
        padding: 1rem;
        display: block;
        position: relative;
        text-align: center;
        line-height: 1.15;
        .not-logged-in & {
          &.hide-for-anon {
            display: none;
          }
        }

        @include breakpoint(large) {
          float: left;
          padding: 1.2rem;
        }

        &:hover, &.active-trail {
          background-color: $button-background-hover;

          &:after {
            width: 30px;
            height: 30px;
          }
        }
      }
    }
  }
}
