footer {
  background-color: $light-gray;
  padding: 0.5rem 0;
  font-size: 0.8rem;
  border-top: 1px solid #fff;
  margin-top: 1rem;
  a {
    color: $black;
  }
  ul {
    margin: 0.5rem 0;
    display: flex;
    justify-content: center;
    li.leaf {
      list-style: none;
      list-style-image: none;
    }
  }
}
