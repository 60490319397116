.node-type-game, .node-type-atlu {
  .breadcrumbs-wrapper {
    display: block;
  }

  .pane-node-field-image {
    margin-bottom: 1rem;
  }

  .pane-node-field-atlu-topic {
    margin-bottom: 1rem;
  }

  .pane-node-field-atlu-objective .field-item {
    margin-bottom: 1rem;
  }

  // Schedule carts
  #tl-play-schedule-add-to-cart,
  #tl-wheel-add-to-cart {
    input[type="submit"] {
      padding: .5rem;
      width: 100%;
      margin-bottom: 0.5rem;
    }
  }

  #tl-wheel-add-to-cart {
    input[type="submit"] {
      @include breakpoint(medium) {
        margin-bottom: 0;
      }
    }
  }

  .pane-play-schedule-cart,
  .pane-wheel-cart {
    input[type="submit"] {
      margin-bottom: 0;
      width: 100%;
    }

    table {
      margin-bottom: 1rem;
    }

    td {
      input[type="submit"] {
        padding: .5rem;
        width: auto;
      }

      &.remove-button {
        text-align: right;
      }
    }
  }
}
