.front {
  section.hero {
    margin-bottom: 0;
  }

  .row {
    margin: 0;
  }

  .panel-panel {
    &.top {
      @include breakpoint(large) {
        margin-bottom: 0;
        height: 40vh;
      }
    }
  }

  .cta-mer-info {
    width: 180px;
    height: 180px;
    display: block;
    background: $primary;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    color: $white;
    padding: 2.2rem 2.2rem;
    font-size: 1.2rem;
    text-align: center;
    line-height: 1.4;

    a {
      color: $white;
    }
  }
}

section.hero {
  .row {
    margin: 0;
  }
}

.three-columns .row {
  padding: 1rem 1rem 2rem 1rem;
  max-width: 100%;
  @include breakpoint(large) {
    padding: 2rem 2rem 2rem 2rem;
  }
  .columns {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.view-facebook-posts {
  width: 100%;
  height: auto;
  background-color: $white;
  margin-bottom: 1rem;
  margin-top: 1rem;

  .button {
    width: 100%;
  }

  img {
    position: relative;
    width: 8rem;
    height: 8rem;
    overflow: hidden;
    display: inline-block;
    border-radius: 50%;
    margin-bottom: 1rem;
  }

  @include breakpoint(large) {
    height: 100%;
    padding: 2vh;
    margin-bottom: 0;
    margin-top: 0;
    display: table;

    .view-content {
      display: table-cell;
      vertical-align: middle;
    }

    h3 {
      font-size: 2.5vh;
    }

    .button {
      width: auto;
    }

    p {
      font-size: 1.5vh;
      line-height: 1.5;
    }
  }
}

.view-quotes {
  width: 100%;
  height: auto;
  background-color: $blue;
  padding: 2rem;
  position: relative;

  &:after {
    content: '.';
    display: table;
    height: 0;
    width: 0;
    visibility: hidden;
  }

  @include breakpoint(large) {
    height: 100%;
  }

  .quotation {
    width: 2rem;
    width: 10%;
    margin-right: 4%;
    float: left;
    @include breakpoint(large) {
      width: 3vh;
    }
  }

  .quote-image {
    text-align: center;
    img {
      position: relative;
      width: 8rem;
      height: 8rem;
      overflow: hidden;
      display: inline-block;
      border-radius: 50%;
      margin-bottom: 1rem;
    }
  }

  .quote-text {
    display: inline-block;
    position: relative;
    float: left;
    width: 86%;
    margin-bottom: 1rem;
    color: $black;

    @include breakpoint(large) {
      font-size: 1.5vh;
      line-height: 1.5;
    }

    .signature {
      font-size: 1.2rem;
      font-weight: 500;
      font-style: italic;
    }
  }
}

.pane-tl-widget-cta {
  div, a, span, & {
    display: block;
    height: 400px;
    @include breakpoint(large) {
      height: 100%;

    }
  }

  .tl_cta_background {
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: $light-gray;
  }
}
