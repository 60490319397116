.page-pdf-preview {
  .region-page-top {
    display: none;
  }

  .pane-file-display {
    .pane-content {
      height: 100vh;
    }
  }
}