
$check-size: 15px;
$check-marg-top: 6px;

ul {
  &.facetapi-facetapi-checkbox-links,
  &.facetapi-date-range-checkboxes {
    //background: red;

    li {
      list-style-type: none;
      list-style-image: none;
      margin: .5rem 0 0.5rem 0;
      font-size: 1.15rem;

      label:before {

      }

      input {
        display: none;
      }

      a {
        display: inline-block;
        margin-right: 13px;
        vertical-align: top;
        color: $black;

        &:before {
          background-image: url(../img/icons/check.svg);
          content: '';
          display: inline-block;
          width: $check-size;
          height: $check-size;
          margin-right: 13px;
          vertical-align: top;
          margin-top: $check-marg-top;
        }

        &.facetapi-active {
          margin-top: $check-marg-top;
          overflow: hidden;
          display: inline-block !important;
          width: $check-size;
          height: $check-size;

          &:before {
            background-image: url(../img/icons/checked.svg);
            margin-top: 0;
          }
        }
      }

      // Level two
      .item-list {
        margin-left: 2rem;
      }
    }
  }
}

form .file-widget.form-managed-file {
  input {
    margin: 0.5rem 0;
  }
}

.form-type-radio, .form-type-checkbox {
  input {
    display: none;
  }
  label {
    margin-left: 0;
    &:before {
      background-image: url(../img/icons/check.svg);
      content: '';
      display: inline-block;
      width: $check-size;
      height: $check-size;
      margin-right: 0.8rem;
      vertical-align: top;
      margin-top: 5px;
    }
  }
  input:checked + label:before {
    background-image: url(../img/icons/checked.svg);
  }
  &.form-disabled {
    opacity: 0.7;
  }
}

.views-exposed-form {
  .views-widget-filter-search_api_views_fulltext, .views-submit-button {
    padding: 0;
    margin: 0;
    input.form-submit, input.form-text {
      margin: 0;
    }
    input.form-submit {
      padding: 0.95rem 1.5rem;
      background: $green;
    }
  }
  .views-exposed-widgets {
    display: flex;
    //justify-content: bottom;
    align-items: flex-end;
  }
}

.node-play_schedule-form {
  .form-managed-file {
    width: 280px;
    overflow: hidden;

    .form-submit {
      display: block;
      padding: .5rem 1rem;
      margin-top: .5rem;
    }
  }
}

.page-node-add-play-schedule, .page-node-edit.node-type-play-schedule, .page-node-clone.node-type-play-schedule {
  .row {
    max-width: none;
  }
}
