#eck-entity-form-edit-school-school {
  .field-name-field-contract {
    input[name$="_remove_button"].form-button-disabled {
      display: none;
    }
  }

  .horizontal-tabs-panes {
    .group-school-info,
    .group-billing-address {
      background-color: $light-gray;
      margin-bottom: 1rem;
      margin-top: 1rem;
      padding: .5rem 2rem 0;
    }

    .group-school-admins,
    .group-school-staff {
      padding: 0;
    }
  }

  input[type="submit"] {
    font-size: 1rem;
    color: $black;
  }
}