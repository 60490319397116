.node-type-page,
.node-type-webform {
  h1#page-title {
    display: none;
  }

  ul.tabs.primary {
    margin-bottom: 1rem;
  }
}

.field-name-body {
  margin-bottom: 2rem;

  h3 {
    margin-top: 2rem;
  }
}

.onecol-sidebar {
  .side-column {
    .panel-pane {
      background-color: $light-gray;
      padding: 2rem;
      margin-bottom: 2rem;

      .pane-title {
        line-height: 1;
        font-family: 'Eczar';
        font-size: 1.8rem;

        a {
          color: black;
          font-family: 'Eczar';
          font-size: 1.8rem;
        }
      }

      .field-item {
        margin-bottom: 1rem;
      }

      .file {
        display: flex;
        line-height: 1.2;

        img {
          margin-right: 1rem;

        }
        a {
          color: #000;
        }
        img, a {
          align-self: top;
        }
      }

      ul {
        margin-bottom: 3rem;
        margin-left: 0;

        li {
          list-style: none;
          padding: 0;
          margin: 0;

          a {
            font-size: 1.2rem;
            color: black;
          }
          &.active-trail a {
            color: $primary;
            font-weight: 700;
          }
        }
      }
    }
  }
}

.media-element-container {
  &.media-default {
    width: 100%;

    .file {
      &.file-image {
        img {
          width: 100%;
        }
      }

      &.file-video {
        video {
          width: 100%;
        }
      }
    }
  }
}

.field-name-field-file-image-caption {
  background-color: $light-gray;
  padding: .8rem 1rem;
}
.file-icon {
  margin-right: 1rem;
}
.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.media-youtube-video {
  .media-youtube-player {
    height: 250px;

    @include breakpoint(medium) {
      height: 400px;
    }

    @include breakpoint(large) {
      height: 450px;
    }
  }
}

.field-name-field-protected-files {
  .callout {
    font-size: .95rem;
    margin-bottom: 1.5rem;
    color: rgba($black, 0.8);
  }
}

.field-name-field-video {
  &.loginwall {
    .field-item {
      position: relative;

      .callout {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: .95rem;
        color: rgba($black, 0.8);
      }

      img {
        width: 100%;
      }
    }
  }
}
