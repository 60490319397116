.view-network-coordinator-schools {
  .views-exposed-form {
    label {
      font-size: 1rem;
    }
  }

  .table-wrapper {
    margin-bottom: 1rem;
    background-color: $light-gray;
    padding: 1.5rem 3rem 2.25rem;
  }

  table {
    width: 100%;

    caption {
      text-align: left;
      margin-bottom: 1rem;
      font-size: 1.25rem;
      font-family: $header-font-family;

      @include breakpoint(medium) {
        font-size: 1.8rem;
      }
    }

    tr {
      background-color: $light-gray;
    }

    th {
      border-bottom: none;
      padding: .5rem 0;
    }

    td {
      padding: 1rem 0;

      &:nth-child(2) {
        width: 20%;
      }
    }

    a {
      color: $black;
      font-weight: bold;
    }
  }
}