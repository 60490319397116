.webform-client-form {
  label {
    font-weight: 700;
    font-size: 1rem;
    font-family: $body-font-family;
  }

  legend {
    span {
      display: block;
      font-size: 1.25rem;
      line-height: 1.4;
      margin-top: 0;
      margin-bottom: .5rem;
      font-family: "Eczar", serif;

      @include breakpoint (medium) {
        font-size: 2.5rem;
      }
    }
  }
  input[type='text'],
  input[type='email'],
  select {
    font-size: 1rem;
    font-family: $body-font-family;
    height: 3rem;
  }

  select {
    background-color: #fefefe;
  }

  .webform-component-radios {
    & > label {
      margin-bottom: .5rem;
      font-size: 1rem;
    }
  }

  .postal-code {
    width: 34%;
    float: left;
    padding-right: 1rem;
  }

  .postal-place {
    width: 66%;
    float: left;
    padding-left: 1rem;
  }

  .form-type-checkbox {
    clear: both;
  }

  .form-actions {
    input {
      width: 100%;

      @include breakpoint(large) {
        width: 10rem;
      }

      &.webform-next,
      &.webform-submit {
        background-color: $green;
        color: $black;
      }
    }
  }
}

.webform-confirmation {
  p {
    font-size: 1rem;
  }
}