// Custom shrink classes
@each $breakpoint in $breakpoint-classes {
  @if $breakpoint != small {
    @include breakpoint($breakpoint) {
      .#{$breakpoint}-shrink {
        flex: 0 0 auto;
        max-width: 100%;
      }
    }
  }
}

button, input, optgroup, select, textarea {
  font-family: $body-font-family;
}

body {
  display: flex;
  flex-direction: column;
}

#page_wrapper {
  min-height: 100vh;
  display: flex;
  flex: 1;
  flex-direction: column;
}

#page {
  flex: 1 0 auto;
}

.button {
  font-weight: 700;
}

h3 {
  font-family: $body-font-family;
  font-weight: 700;
}

.field-type-text-long img {
  height: auto !important;
}

.breadcrumbs-wrapper {
  display: none;
}

// Forms
input[type="submit"] {
  @extend .button;
  margin-right: 1px;
}

.button {
  &.semi-expanded {
    width: 100%;
    background-color: $green;

    &:hover {
      background-color: scale-color($green, $lightness: -10%);
    }

    @include breakpoint(medium) {
      width: 50%;
    }
  }

  &.green {
    background-color: $green;

    &:hover {
      background-color: scale-color($green, $lightness: -10%);
    }
  }
}

input.form-checkbox,
input.form-radio {
  vertical-align: baseline;
}

input[type="text"],
input[type="password"],
input[type='email'],
select {
  font-size: 1.2rem;
  font-family: $body-font-family;
  height: 3rem;
}

div.horizontal-tabs {
  border: none;

  .horizontal-tabs-list {
    border-bottom: 0;
    padding: 0;
    margin: 0;
    overflow: auto;
    padding-left: 10px;
    background: none;
    border: none;
    white-space: nowrap;

    li {
      background: none;
      border: none;
      padding: 0;
      white-space: nowrap;
      display: inline;
      float: none;

      a {
        border: none;
        border-bottom: 5px solid $dark-gray;
        background-color: transparent;
        color: $body-font-color;
        display: inline-block;
        margin-right: 1.5rem;
        margin-bottom: 1rem;
        font-size: 1.1rem;
        padding: 0 0 .5rem;
        font-weight: bold;

        .form-required {
          display: none;
        }

        @include breakpoint(medium) {
          min-width: 140px;
        }

        &:hover {
          background: none;
        }
      }

      &.selected {
        padding: 0;

        a {
          border-bottom-color: $green;
          padding: 0 0 .5rem;
          display: inline-block;
        }
      }

      &:hover {
        background: none;
      }
    }
  }
}

section.hero {
  height: auto;
  position: relative;
  width: 100%;
  padding: 4rem 1rem 0 1rem;
  margin-bottom: 2rem;

  @include breakpoint(large) {
    height: 60vh;
    padding: 3.5rem 2rem 0 2rem;

    p {
      font-size: 2vh;
      line-height: 1.5;
    }
  }

  &.sub-page {
    height: 12rem;

    .hero-content {
      padding: 1rem 0 2rem 1rem;

      @include breakpoint(large) {
        padding: 1rem 0 2rem 2rem;
      }
    }

    .logo {
      @include breakpoint(large) {
        width: 6rem;
      }
    }
  }

  .hero-content {
    background-image: url(../img/hero.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 100%;
    padding: 1.2rem 2rem 2rem 1.5rem;
    position: relative;
    overflow: hidden;

    > * {
      position: relative;
      z-index: 99;
    }

    @include breakpoint(large) {
      padding-right: 40%;
      padding: 4vh 40% 4rem 1rem;
      background-image: none;

      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        background-image: url(../img/hero.jpg);
        background-position: center;
        background-size: 100%;
        animation: zoom-effect 2s ease-in-out forwards;
      }
    }

    h1 {
      margin: 2vh 0;
      font-size: 3.5vh;
      line-height: 1.3;
    }
  }

  .logo {
    width: 6rem;

    @include breakpoint(large) {
      width: 10vh;
    }
  }
}

@keyframes zoom-effect {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.45);
  }
}
.item-list {
  .pager {
    margin: 1.5rem 0 1rem 0;
    li {
      margin: 0;
    }
    a {
      color: $green;
    }
  }
}
